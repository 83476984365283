/* This example requires Tailwind CSS v2.0+ */

import React from "react";



export default function HeorPage() {

  // const [toggle, setToggle] = React.useState(true);
    // const handleToggle = () => {
    //     setToggle(!toggle);
    // };

  return (
    <>   
  <div className="relative bg-gray-50" style={{backgroundColor: "white", overflowX: "hidden", }}>
      <main>
        <div className="container text-start font-s">
          <h1 className=" text-muted">{''}</h1>
          <div className="row" >
            <div className="col-12 col-md-12 ">
              <h4 className="card-title">Work Experience</h4>
            
            </div>
            <span className="mt-1 border-top" style={{width: "95%"}}>{''}</span>
            <div className="col-12 col-md-12 mt-2">
              
            </div>
            <div className="col-12 col-md-12 mx-auto">
              <div>
                <h5 className="card-subtitle mb-2 mt-2" style={{fontSize: "1rem"}}>Full-stack Developer at Phasetree <p className="card-subtitle mb-2 text-muted" style={{fontSize: "0.8rem"}}>March 2023 - present</p></h5>
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                At Phasetree, I work on both the front-end and back-end to create tools for computational material science simulations. 
                I build user-friendly interfaces and reliable server-side processes to support these complex simulations. My focus is on developing solutions that help streamline research and innovation in material science.
                </p>
              </div>
            </div>

            {/* second col */}
          
            
            <div className="col-12 col-md-12 mt-4">
              <div>
                <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}}>Frontend Developer at Walor  <p className="card-subtitle mb-12 text-muted" style={{fontSize: "0.8rem"}}>December 2022 - February 2023</p></h5>
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                As a frontend developer at Walor, I focused on building easy-to-use user interfaces and improving the user experience.
                 Using technologies like React, Redux, and Mixpanel, I added features to make the platform accessible and engaging for users reporting sensitive information. I also tracked user interactions through Mixpanel, which helped us make data-driven improvements to the UI/UX. 
                This work was key in creating a secure and user-friendly environment that promoted transparency and trust.
                </p>
              </div>
            </div>
            {/* third - col */}
           
            <div className="col-12 col-md-12 mt-4">
            <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}}>Freelance Web Developer (Upwork, Fiverr)  <p className="card-subtitle mb-2 text-muted" style={{fontSize: "0.8rem"}}>June 2020 - September 2022</p></h5>
         
            </div>
            <div className="col-12 col-md-12 mx-auto">
              <div>
              
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                  As a freelance web developer, I have had the privilege of working with a diverse range of clients, from individuals
                  clients to businesses aiming to enhance their online presence. My expertise spans across the core technologies of the web,
                  including HTML, CSS, JavaScript, and React. This technical foundation has enabled me to deliver responsive, dynamic, and
                  user-friendly websites tailored to meet the specific needs and goals of each client.
                </p>
              </div>
            </div>

            {/* work fourth */}
            <div className="col-12 col-md-12 mt-4">
            <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}}> Intern Software developer at GrameenPhone  <p className="card-subtitle mb-2 text-muted" style={{fontSize: "0.8rem"}}>February 2019 - June 2019</p></h5>
         
            </div>
            <div className="col-12 col-md-12 mx-auto">
              <div>
              
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                During my internship at GrameenPhone, I worked as a software developer, contributing to the development and maintenance of internal tools. 
                I collaborated with the team to solve technical challenges, learned best practices, and gained hands-on experience in software development.
                </p>
              </div>
            </div>

            {/* edu first col */}
            
            <div className="col-12 col-md-12 mt-4">
              <h4 className="card-title"> Education </h4>
            </div>
            <span className="mt-1 border-top" style={{width: "95%"}}>{''}</span>
            <div className="col-12 col-md-12 mt-4">
            <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}}>Full-stack Developemt Training Program  <p className="card-subtitle mb-2 text-muted" style={{fontSize: "0.8rem"}}>January 2022 - June 2022</p> </h5>
             
            </div>
            <div className="col-12 col-md-12 mx-auto">
              <div>
               
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                  I completed a six-month Full Stack Development program that included practical work with development teams from companies
                  in Germany and Finland. We tackled different coding challenges and learned best practices, improving my skills in both
                  front-end and back-end development. This experience gave me valuable insights into how development teams work and prepared
                  me for handling complex projects more effectively.
                </p>
              </div>
            </div>
            {/* edu second col */}
          
            <div className="col-12 col-md-12 mt-4">
            <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}}>MSc in Business Administration and Leadership <p className="card-subtitle mb-2 text-muted" style={{fontSize: "0.8rem"}}>September 2019 - June 2021</p></h5>
            </div>
            <div className="col-12 col-md-12 mx-auto">
              <div>
                
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                  I completed my Master's in Business Administration and Leadership from Roskilde University, Denmark. The program focused
                  on strategic management, leadership, and innovation, fostering my skills in leading and managing change effectively. It
                  emphasized practical learning and teamwork, preparing me for leadership roles in the modern business landscape.
                </p>
              </div>
            </div>

            {/* edu third col */}
            
            <div className="col-12 col-md-12 mt-4">
            <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}} >BSc in Information Technology Management at National University of Bangladesh <p className="card-subtitle mb-2 text-muted" style={{fontSize: "0.8rem"}}>January 2014 - November 2018</p></h5>
            </div>
            <div className="col-12 col-md-12 mx-auto">
              <div>
               
                <p className="card-text text-muted" style={{fontSize:"0.8rem"}}>
                  I obtained my Bachelor's degree in Information Technology Management, where I gained a solid foundation in managing IT
                  resources, project management, and understanding the strategic role of technology in business. This program emphasized the
                  integration of IT solutions with business objectives, equipping me with the skills to optimize operations and drive
                  innovation within organizations.
                </p>
              </div>
            </div>

            {/* test  */}

           
            <div className="col-12 col-md-12 mt-4">
              <h4 className="card-title"> Personal Project </h4>
            </div>
            <span className="mt-2 border-top" style={{width: "95%"}}> {''}</span>
            
            <div className="col-12 col-md-12 mt-3">
              <div>
                <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}} > React Nifty Hooks</h5>
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                  React Nifty Hooks is a precision-crafted npm package to empower React developers to build more efficient and cleaner React
                  applications. Licensed under MIT, The library offers a variety of hooks that address common functionalities and challenges
                  in React development, saving developer's time and effort.
                  <div></div>
                  <span></span>
                  <a style={{ textDecoration: 'none', }} href="https://www.npmjs.com/package/react-nifty-hooks">
                    Explore React Nifty Hooks on NPM
                  </a>
                  <div className="mb-3"></div>
                </p>
              </div>
            </div>

            {/* project 2 */}
            <div className="col-12 col-md-12 mt-3">
              <div>
                <h5 className="card-subtitle mb-2" style={{fontSize: "1rem"}} > 3D Atomic Viwer</h5>
                <p className="card-text text-muted" style={{fontSize: "0.8rem"}}>
                Driven by curiosity and frustration with existing atomic visualization tools like ASE Atomic Viewer, 3Dmol, and VESTA, 
                I developed a 3D atomic viewer using Three.js. 
                The goal was to create a more visually appealing and feature-rich application that offers accurate atom positioning based on 
                cell parameters, unit cell rendering, bond and polyhedra visualization using the JMOL bonding algorithm, and unit cell replication. 
                When my company saw the initial version, they decided to give me dedicated time to fully develop it and assigned 
                scientific developers to assist me. As a result, the project evolved significantly, and the company adopted it; therefore, the scientific aspects are 
                now proprietary and cannot be disclosed. However, I retain the beta version, which can be demonstrated.
                  <div></div>
                  <span></span>
               
                  <div className="mb-3"></div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    </>
  )
}
