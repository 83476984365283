
import React, { useDeferredValue, useRef } from "react";

import "../../Components/Navbar.scss";

import DropdownNavbar from "../../Components/DropdownNavbar";

import { Canvas, useFrame, useThree,} from '@react-three/fiber'
import { Center, AccumulativeShadows, RandomizedLight, OrbitControls, Environment, SpotLight, useDepthBuffer, useGLTF } from '@react-three/drei';
import * as THREE from 'three'
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar";


const Home = () => {

    const [toggle, setToggle] = React.useState(true);
    const handleToggle = () => {
        setToggle(!toggle);
    };



    



    return (
        <>
            <DropdownNavbar onclick={handleToggle} toggle={toggle} />
            <Navbar click={handleToggle} header={"Mahamudur Rahman Jewel"} />

            <div style={{height: "92vh", overflow: "hidden" , position: "relative",}}>
              <div className="d-flex justify-content-center" style={{position: "absolute", top: "10%", left: "0", zIndex: "200000000000000", width: "100%"}}>
               {toggle &&  <div className=" w-75">
                <h2 style={{textShadow: " 0 10px 7px rgba(0,0,0,0.4),0 -2px 1px #fff", textAlign: "center", fontFamily: 'Wendy One', overflow: "hidden", color: "#1e8449 ", fontSize: "2rem" , paddingBottom: "0.5rem"}}> Hey I am Jewel, welcome to my kingdom. I am a software developer always tinkering and creating. <br /> <span > <Link to="/portfolio" style={{textDecoration: "none", color: "#145a32"}}> Explore My Kingdom </Link></span> </h2>
                </div>}
             
              </div>
            <Canvas shadows camera={{ position: [4, 2.5, 8], fov: 35 }} style={{height: "100%", background: "#E6E6FA"}}>
            {/* <GlassWall  position={[0, 0, 2]} /> */}
            
           
            <Light />
           
{/* {toggle && <Html position={[-2, 1.5, -1]} transform scale={0.1}>
      <div
        style={{
          padding: '20px',
          borderRadius: '15px',
          background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))', // Gradient for a realistic glass effect
          backdropFilter: 'blur(15px) saturate(150%)', // Stronger blur and slight color boost to simulate refraction
          border: '1px solid rgba(255, 255, 255, 0.3)', // More defined border
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)', // Softer shadow for depth
          borderTop: '1px solid rgba(255, 255, 255, 0.7)', // A brighter top border for a glass reflection effect
          borderLeft: '1px solid rgba(255, 255, 255, 0.7)', // A brighter left border for more dimension
        }}
      >
        <div className="row align-items-start">
          <div className="col-6 text-center">
            <img
              src={profileImage}
              className="rounded-circle border border-5 border-light"
              alt="Profile"
              style={{ width: '80%', height: '80%' }}
            />
          </div>
          <div className="col-6 text-center">
          <h3 className="hello">Hello</h3>
          <p className="know-me" style={{fontSize: "2rem"}}>Get to Know Me</p>
          <p style={{fontSize: "1.9rem", textAlign: "left"}}>
          I'm Mahamudur, a software developer who loves building things that work well and are easy to use. 
          I focus on both front-end and back-end, and I enjoy tackling challenging problems and coming up with practical solutions.
           I’m always excited to explore new opportunities and work on projects that need a hands-on, creative approach.
                            </p>
          </div>
        </div>
      </div>
    </Html> }
             */}

             {/* <Text position={[0, 0, 2]} fontSize={0.4} maxWidth={5} > Hey I am Jewel, welcome to my kingdom. I am a software developer always tinkering and creating.</Text> */}

             {/* <Text
    position={[0, -1.2, 3]}  
    rotation={[-Math.PI / 4, 0, 0]} 
    fontSize={0.2}
    color="grey"
    maxWidth={5}  
    textAlign="center" 
    >
      Hey I am Jewel, welcome to my kingdom. I am a software developer always tinkering and creating.
    </Text> */}
      <group position={[0, -0.5, 0]}>

      <Center top position={[-4, 0, -5]}>
          <mesh castShadow scale={0.1}>
            <Model position={[0, 0, 0]} url={"https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/tree-beech/model.gltf"} />
          </mesh>
        </Center>
        <Center top position={[2, 0, -5]}>
          <mesh castShadow scale={0.1}>
            <Model position={[0, 0, 0]} url={'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/tree-lime/model.gltf'} />
          </mesh>
        </Center>

        <Center top position={[4, 0, -6]}>
          <mesh castShadow scale={0.1}>
            <Model position={[0, 0, 0]} url={'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/tree-spruce/model.gltf'} />
          </mesh>
        </Center>
       

      
        <Center top>
          {/* <Suzi rotation={[-0.63, 0, 0]} scale={2} /> */}
        </Center>
        <Center top position={[-4, 0, 1]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="lightblue" />
          </mesh>
        </Center>
        <Center top position={[-1, 2.5, -7]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="lightblue" />
          </mesh>
        </Center>
        <Center top position={[-1.6, 1, -7]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="white" />
          </mesh>
        </Center>
        <Center top position={[3, 3, -2]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="gold" />
          </mesh>
        </Center>
        <Center top position={[-4, 0, 1]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="hotpink" />
          </mesh>
        </Center>
        <Center top position={[2.5, 0, -3]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="indianred" />
          </mesh>
        </Center>
        <Center top position={[-2.5, 0, 0]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="blue" roughness={0.4} metalness={1} />
          </mesh>
        </Center>
        <AccumulativeShadows temporal frames={100} color="white" colorBlend={2} toneMapped={true} alphaTest={0.75} opacity={2} scale={12}>
          <RandomizedLight intensity={Math.PI} amount={8} radius={4} ambient={0.5} position={[5, 5, -10]} bias={0.001} />
        </AccumulativeShadows>
      </group>
      <OrbitControls 
  enableZoom={false}
  minPolarAngle={Math.PI / 2}     // Locks polar angle, no vertical rotation
  maxPolarAngle={Math.PI / 2}     // Locks polar angle, no vertical rotation
  minAzimuthAngle={-Math.PI / 8}  // -22.5 degrees
  maxAzimuthAngle={Math.PI / 2}   // +22.5 degrees
/>
      <Environment preset="lobby" />
    </Canvas>


            </div>
        </>
    );
};

export default Home;


function Light() {

    const depthBuffer = useDepthBuffer({ frames: 1 })
   
    return (<><MovingSpot depthBuffer={depthBuffer} color="#0c8cbf" position={[4, 3, 2]} />
        <MovingSpot depthBuffer={depthBuffer} color="#b00c3f" position={[2, 3, 1]} />
         </>)
  }
  




function MovingSpot({ vec = new THREE.Vector3(), ...props }) {
    const light = useRef()
    const viewport = useThree((state) => state.viewport)
    useFrame((state) => {
      light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
      light.current.target.updateMatrixWorld()
    })
    return <SpotLight castShadow ref={light} penumbra={1} distance={12} angle={0.35} attenuation={5} anglePower={1} intensity={3} {...props} />
  }
  

  // function GlassWall() {
  //   const meshRef = useRef();
  
  //   // Create the rounded rectangle shape
  //   const shape = useMemo(() => {
  //     const shape = new THREE.Shape();
  //     const radius = 0.1; // Radius of the corners
  //     const width = 5;
  //     const height = 5;
  
  //     shape.moveTo(-width / 2 + radius, -height / 2);
  //     shape.lineTo(width / 2 - radius, -height / 2);
  //     shape.quadraticCurveTo(width / 2, -height / 2, width / 2, -height / 2 + radius);
  //     shape.lineTo(width / 2, height / 2 - radius);
  //     shape.quadraticCurveTo(width / 2, height / 2, width / 2 - radius, height / 2);
  //     shape.lineTo(-width / 2 + radius, height / 2);
  //     shape.quadraticCurveTo(-width / 2, height / 2, -width / 2, height / 2 - radius);
  //     shape.lineTo(-width / 2, -height / 2 + radius);
  //     shape.quadraticCurveTo(-width / 2, -height / 2, -width / 2 + radius, -height / 2);
  
  //     return shape;
  //   }, []);
  
  //   return (
    
  //     <mesh ref={meshRef} position={[-2, 1.5, -1.001]} rotation={[0, 0, 0]} scale={[0.736, 0.303, 2.5]}>
  //       <extrudeGeometry args={[shape, { depth: 0.01, bevelEnabled: false }]} />
  //       <meshPhysicalMaterial
  //         color="lightblue"
  //         transparent
  //         opacity={0.5}
  //         roughness={0}
  //         clearcoat={1}
  //         metalness={0.4}
  //         transmission={1}
  //         reflectivity={0.7}
  //       />
  //     </mesh>
      
  //   );
  // }

  
  
  function Model({ url, ...props }) {
    // useDeferredValue allows us to defer updates, the component is market by React
    // so that it does *not* run into the fallback when something new loads
    const deferred = useDeferredValue(url)
    // We can find out the loading state by comparing the current value with the deferred value
    // const isLoading = url !== deferred
    const { scene } = useGLTF(deferred)
    // <primitive object={...} mounts an already existing object
    return <primitive object={scene} {...props} />
  }
  
  
  

